#map {
  width:100%;
  height:600px;
  margin:30px 0 50px;
  float:left;
  @media all and (max-width: @tablet-breakpoint) {
    max-height: 400px;
    margin: 0;
  }
  @media all and (max-width: @mobile-breakpoint) {
    box-sizing: content-box;
    margin-bottom: 35px;
  }
}
.no-scroll {
  overflow-y: hidden;
}
.scroll {
  overflow-y: auto;
}
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
body {
  background-color: @lgrey;
  font: 16px 'KSHelveticaNeueCYR-Roman';
}
p {
  font: 16px 'KSHelveticaNeueCYR-Light';
  line-height: 24px;
  color: @ltext;
  margin: 0;
}
h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-family: 'KSHelveticaNeueCYR-Medium';
  font-weight: 300;
  color: @text;
}
h1 {
  font-size: 34px;
}
h2 {
  font: 36px 'KSHelveticaNeueCYR-Roman';
}
h3 {
  font-size: 32px;
}
h4 {
  font: 24px 'KSHelveticaNeueCYR-Light';
  text-transform: uppercase;
}
h5 {
  font-size: 28px;
}
h6 {
  font-size: 13px;
  text-transform: uppercase;
  color: @lblue;
}
ul {
  li {
    list-style: none;
  }
}
a {
  color: @text;
  transition: 0.3s;
  &:hover {
    color: @blue;
    transition: 0.3s;
  }
}
input, textarea {
  &::placeholder {
    color: @footer-text;
    font: 16px 'KSHelveticaNeueCYR-Light';
  }
}
.js-hidden {
  display: none;
}
.search {
  h1 {
    margin-bottom: 35px;
  }
}
.page h1,
.search h1 {
  margin-bottom: 35px;
  @media all and (max-width: @tablet-breakpoint) {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
  }
  @media all and (max-width: @mobile-breakpoint) {
    font-size: 22px;
    text-align: left;
    padding-left: 20px;
  }
}
.search h1 {
    // padding-top: 25px;
    @media all and (max-width: @tablet-breakpoint) {
      padding-top: 25px;
      border-top: 1px solid @input-text;
    }
}
.page,
.search-header,
.search {
  .header {
    @media all and (max-width: @tablet-breakpoint) {
      padding-top: 25px;
      border-top: 1px solid @input-text;
    }
    @media all and (max-width: @mobile-breakpoint) {
      text-align: left;
      padding-left: 20px;
      font-size: 22px;
    }
  }
}
.page {
  float: left;
  @media all and (max-width: @tablet-breakpoint) {
    display: block;
    width: 100%;
  }
  h4 {
    margin: 45px 0 35px 0;
  }
}
.page_404 {
  .content,
  & > h4 {
    @media all and (max-width: @tablet-breakpoint) {
      display: none;
    }
  }
}
.search .content {
  @media all and (max-width: @mobile-breakpoint) {
    padding-bottom: 70px;
  }
}
.content {
  float: left;
  width: 100%;
  margin: 0 0 65px 0;
  padding-bottom: 120px;
  position: relative;
  @media all and (max-width: @tablet-breakpoint) {
    margin: 0 0 20px 0;
    padding-bottom: 70px;
    margin: 0 0 40px 0;
  }
  @media all and (max-width: @mobile-breakpoint) {
    display: flex;
    flex-direction: column;
    margin: 0 0 30px 0;
    padding-bottom: 70px;
  }
  .sidebar {
    @media all and (max-width: @mobile-breakpoint) {
      order: 2;
    }
  }
  .block {
    margin: 0 0 20px 0;
    @media all and (max-width: @tablet-breakpoint) {
      margin: 0 0 20px 15px;
    }
    @media all and (max-width: 768px) {
      margin: 0 0 20px 11px;
    }
    @media all and (max-width: @mobile-breakpoint) {
      margin: 0 0 10px 0;
    }
  }
  .block ~ .block-tags {
    @media all and (max-width: @mobile-breakpoint) {
      margin: 0 0 20px 0;
    }
  }
}
.wrap {
  margin: 0 auto;
  max-width: 1200px;
  min-height: calc( ~"100vh - 220px" );
  // min-width: 1200px;
  // min-height: 600px;
  @media all and (max-width: @tablet-breakpoint) {
    width: 100%;
    min-height: calc( ~"100vh - 230px" );
  }
  @media all and (max-width: @mobile-breakpoint) {
    min-height: calc( ~"100vh - 330px" );
  }
}
.clear {
  clear: both;
}
.article {
  .block-sidebar {
    border-top: 8px solid @aqua;
  }
  .article__header {
      border-bottom: 8px solid @aqua;
      &_orange {
        border-color: @orange;
      }
      &_lblue {
        border-color: @lblue;;
      }
  }
}
.divider {
  display: block;
  margin: 20px 0 30px 0;
  border-bottom: 1px solid @divider;
}
.btn-top {
  float: right;
  width: 50px;
  height: 50px;
  border: 1px solid @input-text;
  line-height: 50px;
  color: @footer-text;
  background: url('../img/arrow.svg') no-repeat center;
  background-size: 16px 16px;
  text-decoration: none;
  transition: 0.3s;
  @media all and (max-width: @tablet-breakpoint) {
    display: none;
  }
  &:hover {
    border: 1px solid @grey;
    transition: 0.3s;
  }
}
// .subscribe-item i.logo {
//   margin-top: 5px;
//   display: inline-block;
//   vertical-align: bottom;
//   width: 200px;
//   height: 25px;
//   background: url('../img/logo.svg') no-repeat center;
//   background-size: cover;
//   // background-size: 170px;
// }
.logo, .logo-subhead {
  text-align: center;
}
.logo {
  display: block;
  width: 300px;
  margin: 0 auto;
  @media all and (max-width: @tablet-breakpoint) {
    width: 250px;
  }
  @media all and (max-width: @tablet-breakpoint) {
    width: 180px;
  }
}
.logo-head {
  width: 100%;
}
.logo-subhead {
  display: inline-block;
  width: 100%;
  margin-top: 10px;
  font: 16px 'KSHelveticaNeueCYR-Light';
  white-space: nowrap;
  text-align: justify;
	text-align-last: justify;
  @media all and (max-width: @tablet-breakpoint) {
    font-size: 0.85rem;
    display: none;
  }
}
.logo-top {
  position: relative;
  z-index: 1;
  margin: 50px auto 23px;
  color: @lblue;
  font-size: 0;
  @media all and (max-width: @tablet-breakpoint) {
		margin: 20px auto;
  }
}
.logo-footer {
  width: 245px;
  font-size: 0;
  @media all and (max-width: @mobile-breakpoint) {
    width: 210px;
  }
}
.logo-subhead-footer {
  color: @footer-text;
  font-size: 13px;
  @media all and (max-width: @mobile-breakpoint) {
    font-size: 11px;
    display: inline-block;
    width: 210px;
    margin: 0;
    line-height: 100%;
  }

}
.head {
  background-color: @lgrey;
  @media all and (max-width: @tablet-breakpoint) {
    position: relative;
    display: block;
  }
}
.column {
  display: flex;
  flex-wrap: wrap;
  width: 66%;
  @media all and (max-width: @tablet-breakpoint) {
    display: inline-block;
  }
}

/*------------------------------------------------------------------------------
/*                    Цветные блоки
------------------------------------------------------------------------------*/
.block__blue {
  img {
    border-bottom: 6px solid @blue;
  }
  .block-category {
    color: @blue;
  }
}
.block__lblue {
  img {
    border-bottom: 6px solid @lblue;
  }
  .block-category {
    color: @lblue;
  }
}
.block__aqua {
  img {
    border-bottom: 6px solid @aqua;
  }
  .block-category {
    color: @aqua;
  }
}
.block__orange {
  img {
    border-bottom: 6px solid @orange;
  }
  .block-category {
    color: @orange;
  }
}
.block__grey {
  img {
    border-bottom: 6px solid @grey;
  }
  
  .block-category {
    color: @grey;
  }
}
/*------------------------------------------------------------------------------
/*            Блок подписки
------------------------------------------------------------------------------*/

.counts {
  position: absolute;
  display: block;
  bottom: 35px;
  font: 16px 'KSHelveticaNeueCYR-Roman';
  color: @grey;
  &_right {
    position: relative;
    bottom: 0;
    margin-top: 20px;
    @media all and (max-width: @tablet-breakpoint) {
      margin: 0;
      margin-right: 50px;
    }
  }
  @media all and (max-width: @tablet-breakpoint) {
    font-size: 14px;
  }
}
.counts__views {
  position: relative;
  top: 0;
  left: 0;
  display: inline-block;
  margin-right: 20px;
  padding-left: 30px;
  vertical-align: top;
  @media all and (max-width: @tablet-breakpoint) {
    line-height: 18px;
    padding-left: 25px;
  }
  @media all and (max-width: @mobile-breakpoint) {
    padding-left: 21px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 3px;
    left: 0;
    display: inline-block;
    width: 23px;
    height: 15px;
    margin-right: 5px;
    color: @grey;
    background: url('../img/i-views.svg');
    background-size: cover;
    @media all and (max-width: @tablet-breakpoint) {
      width: 19px;
      height: 12px;
    }
    @media all and (max-width: @mobile-breakpoint) {
      top: 1px;
      width: 16px;
      height: 10px;
    }
  }
}
.counts__comments {
  position: relative;
  top: 0;
  left: 0;
  display: inline-block;
  margin-right: 20px;
  padding-left: 30px;
  vertical-align: top;
  @media all and (max-width: @tablet-breakpoint) {
    line-height: 18px;
    padding-left: 22px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 2px;
    bottom: 0;
    left: 0;
    width: 19px;
    height: 16px;
    margin-right: 5px;
    color: @grey;
    background: url('../img/i-comments.svg');
    background-size: cover;
    @media all and (max-width: @tablet-breakpoint) {
      top: 2px;
      width: 16px;
      height: 14px;
    }
    @media all and (max-width: @mobile-breakpoint) {
      top: 0;
      width: 14px;
      height: 12px;
    }
  }
}

/*------------------------------------------------------------------------------
                              ФОРМЫ
------------------------------------------------------------------------------*/
.block-form {
  position: relative;
  display: inline-block;
}
.block-form-field {
  position: relative;
  display: inline-block;
  vertical-align: top;
  height: 50px;
  // width: 340px;
  // margin-right: 30px;
  border: 0;
  font-size: 16px;
  color: @text;
  @media all and (max-width: @tablet-breakpoint) {
    margin-right: 10px;
  }
  &_grey {
    border-color: @input-text;
  }
  input {
    width: 100%;
    height: 100%;
    border: 0;
    background: none;
    border-bottom: 1px solid @blue;
    &.error {
      border-color: @red;
      color: @red;
    }
    &:focus {
      outline: 0;
    }
    @media all and (max-width: @tablet-breakpoint) {
      padding-right: 25px;
    }
  }

  &__email {
    width: 60%;
    input {
      padding-left: 40px;
    }
    .i-email {
        &:before {
        content: '';
        position: absolute;
        left: 5px;
        width: 20px;
        height: 16px;
        margin-top: 17px;
        background-image: url('../img/email.svg');
        background-size: cover;
      }
    }
  }
}

/*------------------------------------------------------------------------------
                              МЕНЮ
------------------------------------------------------------------------------*/
.menu {
  background-color: @lgrey;

  ul {
    // display: inline-block;
    list-style: none;
    padding: 0;
    li {
      display: inline-block;
      color: @text;
    }
    li a {
      color: @text;
      transition: 0.2s;
    }
    li a:hover {
      color: rgba(51, 51, 51, 0.7);
      transition: 0.2s;
    }
    li.active {
      a {
        color: rgba(51, 51, 51, 0.7);
      }
    }
  }
}
.logo-nologostudio {
  position: relative;
  display: inline-block;
  margin: 10px 0;
  padding-left: 30px;
  font-size: 14px;
  color: @footer-text;
  @media all and (max-width: @mobile-breakpoint) {
    position: relative;
    margin-top: 20px;
    text-align: center;
    font-size: 14px;
    padding: 40px 0 0 0;
    display: block;
  }
  span {
    display: block;
    
  }
  a {
    color: @footer-text;
    text-decoration: none;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: inline-block;
    vertical-align: middle;
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    width: 24px;
    height: 31px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'608\' height=\'768\' viewBox=\'0 0 608 768\'%3E%3Cpath fill-rule=\'evenodd\'  fill=\'rgb(170, 172, 178)\' d=\'M49.56 49.545h173.418v247.739h49.548V-.003H.013v297.287h49.548V49.545zM322.073-.002v297.287h272.512V-.002H322.072zm222.966 247.74H371.621V49.547h173.418v198.191zM99.108 569.798H24.787l.396-222.544-25.17-.422v247.739h99.096v-24.774zm24.773 24.774H247.75V346.833H123.881v247.739zm24.774-222.965l74.321.05v198.142h-74.321V371.608zm322.062-24.775v247.739h123.869l-.371-247.319-123.499-.422zm99.096 222.966h-74.321V371.607l74.321.05v198.142zM297.3 594.572h123.869V470.703h-49.548l.371 24.452 24.401.322v74.321h-74.321V371.607h99.096v-24.774H297.299v247.739zM.011 718.517H49.36l.198 24.7-49.548.098v24.675h74.321v-74.321H24.783v-24.774h49.548v-24.774H.01v74.396zm99.097-49.624h24.774v99.096h24.774v-99.096h24.774v-24.774H99.109v24.774zm173.418-24.773v99.096h-49.548V644.12h-24.774v123.869H297.3l-.049-123.796-24.724-.074zm74.322 24.773h49.548v-24.774h-74.321v123.869h74.321v-24.774h-49.548v-74.321zm49.547 73.902l24.774.422v-74.321l-24.774.074v73.827zm49.548 25.195h24.774V644.121h-24.774V767.99zm49.548 0h99.096V644.121h-99.096V767.99zm24.774-99.097h49.548v74.321h-49.548v-74.321z\'/%3E%3C/svg%3E");
    @media all and (max-width: @mobile-breakpoint) {
      left: calc(~"50% - 12px");
      top: 0;
    }
  }
}
.icon-tags {
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  margin-left: 20px;
  margin-right: 10px;
  background: #ffcc66;
  position: relative;
  &:before {
    content: "";
    width: 0; 
    height: 0;
    position: absolute; 
    left: 100%; 
    top: 0px;
    border-top: 5px solid transparent;
    border-left: 5px solid #ffcc66;
    border-bottom: 5px solid transparent;
  }
}
.text {
  &__orange {
    color: @orange;
  }
}