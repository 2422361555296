
/*------------------------------------------------------------------------------
                          FONTS
------------------------------------------------------------------------------*/

@font-face {
  font-family: 'KSHelveticaNeueCYR-Roman';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/KSHelveticaNeueCYR-Roman.ttf") format("truetype"),
      url('../fonts/KSHelveticaNeueCYRRoman.woff2') format('woff2'),
      url('../fonts/KSHelveticaNeueCYRRoman.woff') format('woff');
}
@font-face {
  font-family: 'KSHelveticaNeueCYR-Black';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/KSHelveticaNeueCYR-Black.ttf") format("truetype"),
      url('../fonts/KSHelveticaNeueCYRBlack.woff2') format('woff2'),
      url('../fonts/KSHelveticaNeueCYRBlack.woff') format('woff');
}
@font-face {
  font-family: 'KSHelveticaNeueCYR-BlackIt';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/KSHelveticaNeueCYR-BlackIt.ttf") format("truetype"), 
      url('../fonts/KSHelveticaNeueCYRBlackIt.woff2') format('woff2'),
      url('../fonts/KSHelveticaNeueCYRBlackIt.woff') format('woff');
}
@font-face {
  font-family: 'KSHelveticaNeueCYR-Bold';
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/KSHelveticaNeueCYRBold.ttf") format("truetype"), 
      url('../fonts/KSHelveticaNeueCYRBold.woff2') format('woff2'),
      url('../fonts/KSHelveticaNeueCYRBold.woff') format('woff');
}
@font-face {
  font-family: 'KSHelveticaNeueCYR-BoldCond';
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/KSHelveticaNeueCYRBoldCond.ttf") format("truetype"), 
      url('../fonts/KSHelveticaNeueCYRBoldCond.woff2') format('woff2'),
      url('../fonts/KSHelveticaNeueCYRBoldCond.woff') format('woff');
}
@font-face {
  font-family: 'KSHelveticaNeueCYR-BoldIt';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/KSHelveticaNeueCYRBoldIt.ttf") format("truetype"),  
      url('../fonts/KSHelveticaNeueCYRBoldIt.woff2') format('woff2'),
      url('../fonts/KSHelveticaNeueCYRBoldIt.woff') format('woff');
}
@font-face {
  font-family: 'KSHelveticaNeueCYR-Cond';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/KSHelveticaNeueCYRCond.ttf") format("truetype"),  
      url('../fonts/KSHelveticaNeueCYRCond.woff2') format('woff2'),
      url('../fonts/KSHelveticaNeueCYRCond.woff') format('woff');
}
@font-face {
  font-family: 'KSHelveticaNeueCYR-Italic';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/KSHelveticaNeueCYRItalic.ttf") format("truetype"),  
      url('../fonts/KSHelveticaNeueCYRItalic.woff2') format('woff2'),
      url('../fonts/KSHelveticaNeueCYRItalic.woff') format('woff');
}
@font-face {
  font-family: 'KSHelveticaNeueCYR-Light';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/KSHelveticaNeueCYRLight.ttf") format("truetype"),  
      url('../fonts/KSHelveticaNeueCYRLight.woff2') format('woff2'),
      url('../fonts/KSHelveticaNeueCYRLight.woff') format('woff');
}
@font-face {
  font-family: 'KSHelveticaNeueCYR-LightCn';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/KSHelveticaNeueCYRLightCn.ttf") format("truetype"),  
      url('../fonts/KSHelveticaNeueCYRLightCn.woff2') format('woff2'),
      url('../fonts/KSHelveticaNeueCYRLightCn.woff') format('woff');
}
@font-face {
  font-family: 'KSHelveticaNeueCYR-LightIt';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/KSHelveticaNeueCYRLightIt.ttf") format("truetype"),  
      url('../fonts/KSHelveticaNeueCYRLightIt.woff2') format('woff2'),
      url('../fonts/KSHelveticaNeueCYRLightIt.woff') format('woff');
}
@font-face {
  font-family: 'KSHelveticaNeueCYR-Medium';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/KSHelveticaNeueCYRMedium.ttf") format("truetype"),  
      url('../fonts/KSHelveticaNeueCYRMedium.woff2') format('woff2'),
      url('../fonts/KSHelveticaNeueCYRMedium.woff') format('woff');
}
