.block-link {
  position: relative;
  display: inline-block;
  border: 0;
  width: 100%;
  height: 385px;
  background: url('../img/block__link-bg.png') no-repeat center;
  background-size: cover;
  color: @white;
  margin-bottom: 20px;
  @media all and (max-width: @mobile-breakpoint) {
    margin: 0 0 10px 0;
    height: 320px;
  } 
  &:hover {
    color: #ffffff;
  }
  .block-link__logo {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 100px 0;
    span {
      position: relative;
      top: 20px;
      font: 16px 'KSHelveticaNeueCYR-Bold';
      text-transform: uppercase;
    }
  }
  .block-link__link {

    width: 100%;
    display: inline-block;
    text-align: center;
    font: 20px 'KSHelveticaNeueCYR-Light';
  }
  .block-link__top {
    position: absolute;
    top: 0;
    margin-top: 90px;
    @media all and (max-width: 640px) {
      text-transform: uppercase;
      font: 14px 'KSHelveticaNeueCYR-Bold';
    }
  }
  .block-link__bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: 30px;
    @media all and (max-width: 640px) {
      font-size: 18px;
    }
  }
}