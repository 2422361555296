.menu-top {
  width: 100%;
  text-align: center;
  @media all and (max-width: @tablet-breakpoint) {
    display: flex;
    justify-content: space-between;
    margin: 0 20px 0;
  }
  @media all and (max-width: @mobile-breakpoint) {
    display: none;
  }
  &__item {
    white-space: nowrap;
    margin: 0 25px;
    &:last-child {
      margin-right: 0;
    }
    &:first-child {
      margin-left: 0;
    }
    @media all and (max-width: 1070px) {
      margin: 0 15px;
    }
    @media all and (max-width: @tablet-breakpoint) {
      display: flex;
      justify-content: space-between;
    }
    a {
      text-decoration: none;
      color: @text;
      transition: 0.3s;
      &:hover {
        color: rgba(51, 51, 51, 0.7);
        transition: 0.3s;
      }
    }
  }
}
.btn-send-article {
  text-decoration: none;
  // padding: 0;

  @media all and (max-width: @tablet-breakpoint) {
    position: absolute;
    background: none;
    width: auto;
    // border: 0;
    color: @grey;
    position: absolute;
    top: 0;
    left: 20px;
    font-size: 12px;
    height: auto;
    padding: 5px 0 0 0;
    &:hover {
      background: none;
      border: 0;
      color: @grey;
    }
  }
}
