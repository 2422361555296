.search-main {
  width: 100%;
  height: 115px;
  line-height: 115px;
  background-color: @white;
  border-top: 6px solid @lsky;
  margin-bottom: 30px;
  text-align: center;
  @media all and (max-width: 640px) {
    height: 75px;
  }
  &__field {
    padding: 30px 30px 10px 5px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 97%;
    line-height: 30px;
    border-bottom: 1px solid @blue;
    @media all and (max-width: 640px) {
      width: 92%;
      padding: 12px 30px 5px 5px;
    }
  }
  &__input {
    width: 100%;
    border: 0;
    font: 24px 'KSHelveticaNeueCYR-Light';
    color: @blue;
    @media all and (max-width: 640px) {
      font-size: 16px;
    }
    &:focus {
      outline: 0;
    }
  }
  &__btn-close {
    position: absolute;
    right: 5px;
    top: 40px;
    display: inline-block;
    width: 20px;
    height: 20px;
    @media all and (max-width: 640px) {
      top: 17px;
    }
    &:before, &:after {
      position: absolute;
      left: 15px;
      content: ' ';
      height: 20px;
      width: 2px;
      background-color: @lsky;
      transition: 0.3s;
    }
    &:hover:before, &:hover:after {
      background-color: @blue;
      transition: 0.3s;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}
.header-search {
  margin-bottom: 35px;
  @media all and (max-width: @tablet-breakpoint) {
    margin-left: 16px;
  }
  @media all and (max-width: @mobile-breakpoint) {
    margin-bottom: 20px;
    font-size: 16px;
    margin-left: 16px;
  }
}


