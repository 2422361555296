.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,250, 0.7);
  // overflow-y: hidden;
   z-index: 999;

  &.active {
    display: block;
  }
}
.popup-article {
  position: relative;
  background-color: @white;
  width: 70%;
  border-top: 6px solid @aqua;
  padding: 50px 100px;
  float: none;
  display: block;
  margin: 100px auto;
  top: 0;
  left: 0;
  z-index: 999;
  @media all and (max-width: @tablet-breakpoint) {
    width: 80%;
    padding-bottom: 100px;
    margin: 30px auto;
    padding: 50px 50px 30px;
  }
  @media all and (max-width: @mobile-breakpoint) {
    width: 95%;
    padding: 20px 15px 20px;
    margin: 30px auto;
    overflow-y: auto;
  }
  h4 {
    text-align: center;
    text-transform: none;
    margin-bottom: 50px;
  }
  &__header {
    text-align: center;
    margin-bottom: 50px;
    @media all and (max-width: @tablet-breakpoint) {
      font-size: 24px;
      margin-bottom: 10px;
    }
    @media all and (max-width: @mobile-breakpoint) {
      font-size: 18px;
      text-align: left;
    }
    &_aqua {
      color: @aqua;
    }
  }
  &__fields {
    display: flex;
    @media all and (max-width: @tablet-breakpoint) {
      flex-direction: column;
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    margin-right: 60px;
    width: 40%;
    margin-top: 10px;
    @media all and (max-width: @tablet-breakpoint) {
      width: 100%;
      margin-bottom: 25px;
    }
  }
  &__item {
    float: left;
    margin-top: 10px;
    @media all and (max-width: @tablet-breakpoint) {
      margin-right: 0;
      height: 35px;
      margin-top: 16px;
    }
    @media all and (max-width: @tablet-breakpoint) {
      margin-right: 0;
      height: 25px;
      margin-top: 15px;
    }
  }
  &__comment {
    float: right;
    width: 60%;
    height: 190px;
    clear: right;
    @media all and (max-width: @tablet-breakpoint) {
      width: 100%;
    }
    @media all and (max-width: @tablet-breakpoint) {
      height: 120px;
    }
    textarea {
      width: 100%;
      height: 100%;
      padding: 10px;
      border: 1px solid @blue;
      color: @text;
      &:focus {
        outline: 0;
      }
      &.error {
        border-color: @red;
      }
    }
  }
  &__btn {
    display: block;
    width: 40%;
    height: 55px;
    margin: 50px auto;
    position: relative;
    @media all and (max-width: 1340px) {
      width: 70%;
    }
    p {
      display: block;
      // margin: auto 70px;
      width: 70%;
    }
  }
  &__refer {
    display: block;
    margin: 0 auto;
    margin-top: 25px;
    width: 40%;
    text-align: center;
    @media all and (max-width: 1340px) {
      width: 70%;
    }
    @media all and (max-width: @tablet-breakpoint) {
      position: static;
      bottom: 20px;
      left: 0;
      right: 0;
      text-align: center;
      font-size: 12px;
      width: 70%;
    }
    @media all and (max-width: @mobile-breakpoint) {
      position: static;
      width: 90%;
    }
  }
  &_message {
    width: 38%;
    @media all and (max-width: @tablet-breakpoint) {
      padding-left: 30px;
      padding-right: 30px;
      width: 80%;
      padding-bottom: 40px;
    }
    @media all and (max-width: @mobile-breakpoint) {
      width: 90%;
      padding-bottom: 30px;
    }
    h1 {
      @media all and (max-width: @mobile-breakpoint) {
        font-size: 22px;
        text-align: center;
        margin-bottom: 20px;
      }
    }
    h4 {
      @media all and (max-width: @mobile-breakpoint) {
        font-size: 20px;
        margin-bottom: 30px;
      }
    }
    p {
      text-align: inherit;
      line-height: 32px;
      text-indent: 20px;
      @media all and (max-width: @mobile-breakpoint) {
        line-height: 20px;
        text-indent: 0;
        font-size: 14px;
        text-align: center;
      }
    }
    a {
      text-decoration: underline;
      display: inline;
    }
    .popup-article__fields {
      margin-bottom: 50px;
    }
  }
}

.file-upload {
  display: block;
  position: relative;
  overflow: hidden;
  margin-top: 50px;
  @media all and (max-width: @tablet-breakpoint) {
    margin-top: 0;
  }
  &__btn {
    display: block;
    position: relative;
    width: 40%;
    margin: 30px auto;
    @media all and (max-width: 1340px) {
      width: 70%;
    }
    @media all and (max-width: @tablet-breakpoint) {
      width: 80%;
      font-size: 14px;
    }
    span {
      position: relative;
      display: block;
      height: 100%;
      padding-left: 60px;
      color: @ltext;

      &:before {
        content: '';
        position: absolute;
        top: 1px;
        left: 0;
        display: inline-block;
        vertical-align: middle;
        width: 40px;
        height: 40px;
        background: none;
        border: 2px dashed @blue;
        border-radius: 3px;
        cursor: pointer;
      }

      &:after {
        content: '+';
        cursor: pointer;
        position: absolute;
        font: 2em 'KSHelveticaNeueCYR-Light';
        top: -2px;
        left: 10px;
        color: @blue;

        @media all and (max-width: @tablet-breakpoint) {
          height: 40px;
          width: 40px;
          left: 0;
          line-height: 36px;
          text-align: center;
          top: 1px;
        }
      }
    }
  }
  &__mark {
    width: 45%;
    position: relative;
    margin: 10px auto;
    vertical-align: top;
    @media all and (max-width: 1340px) {
      width: 70%;
    }
    @media all and (max-width: @tablet-breakpoint) {
      width: 80%;
    }
    @media all and (max-width: @mobile-breakpoint) {
      width: 100%;
    }
  }
  &__file-name {
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    padding-right: 15px;
    width: 70%;
    @media all and (max-width: @mobile-breakpoint) {
      width: auto;
      max-width: 65%;
      font-size: 12px;
      padding: 0;
    }
    @media all and (max-width: @tablet-breakpoint) {
      width: 69%;
      font-size: 14px;
      padding: 0;
    }
  }
  &__file-size {
    float: right;
    padding-right: 20px;
    color: #B3B3B3;
    @media all and (max-width: @mobile-breakpoint) {
      font-size: 12px;
      padding-top: 5px;
    }
    @media all and (max-width: @tablet-breakpoint) {
      padding-top: 3px;
    }
  }
  &__btn-remove-file {
    position: absolute;
    top: 5px;
    right: 0;
    display: block;
    width: 12px;
    height: 12px;
    background: url('../img/i-close-red.svg') no-repeat center;
    cursor: pointer;
  }
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;
    &.error ~ span{
      &:before {
        border: 2px dashed @red;
      }
      &:after {
        color: @red;
      }
    }
    &:focus {
      outline: 0;
    }
  }
}