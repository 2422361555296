.sidebar-article {
  position: relative;
  float: right;
  width: 32%;
  background-color: @white;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
  vertical-align: top;
  transition: 0.5s;
  margin: 0;
  padding: 20px;
  transition: 0.5s;
  border-top: 8px solid @aqua;
  a {
    display: inline-block;
    text-decoration: none;
  }
  &__category {
    text-transform: uppercase;
    color: @aqua;
    font: 13px 'KSHelveticaNeueCYR-Roman';
    margin-bottom: 30px;
  }
  &__author {
    font: 16px'KSHelveticaNeueCYR-Roman';
    color: @grey;
    margin-bottom: 23px;
  }
  &__header {
    margin-bottom: 20px;
    h5 {
      font: 20px'KSHelveticaNeueCYR-Medium';
    }

  }
}