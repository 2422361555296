.block-list {
  position: relative;
  float: left;
  &__image {
    position: absolute;
    left: 0;
    width: 15%;
    margin: 10px 0 0 70px;
    float: left;
    img {
      width: 190px;
    }
    @media all and (max-width: @tablet-breakpoint) {
      position: relative;
      display: block;
      width: 100%;
      margin: 0;
      clear: both;
      margin-bottom: 30px;
      img {
        display: block;
        width: 190px;
        margin: 0 auto;
      }
    }
  }
  &__item {
    @media all and (max-width: @mobile-breakpoint) {
      padding: 25px 0 20px;
      margin: 0 15px;
    }
    p {
      line-height: 1.5em;
      font-family: 'KSHelveticaNeueCYR-Roman';
      margin: 17px 0;
      &:last-child {
        margin-bottom: 0;
      }
      @media all and (max-width: @tablet-breakpoint) {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 10px;
      }
    }
    h2 {
      font: 28px 'KSHelveticaNeueCYR-Roman';
      margin-bottom: 26px;
      @media all and (max-width: @tablet-breakpoint) {
        display: block;
        clear: both;
        margin-bottom: 15px;
        font: 20px 'KSHelveticaNeueCYR-Light';
      }
    }
    position: relative;
    padding: 40px 0;
    border-bottom: 1px solid @divider;
    &:last-child {
      border: 0;
    }
    &-right {
      float: right;
      padding-left: 28%;
      padding-right: 25px;
      &:first-child {
        padding-top: 23px;
      }
      @media all and (max-width: @tablet-breakpoint) {
        float: none;
        display: block;
        padding: 25px;
      }
      @media all and (max-width: @mobile-breakpoint) {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 15px;
      }
    }
  }
}