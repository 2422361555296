.search-mobile {
  position: absolute;
  right: 20px;
  // width: 0px;
  top: 0;
  height: 25px;
  padding-top: 5px;
  width: 100%;
  display: none;
  @media all and (max-width: @mobile-breakpoint) {
    display: inline-block;
    text-align: right;
  }
  &__btn-open {
    background: url(../img/search.svg) no-repeat;
    display: inline-block;
    text-align: right;
    width: 20px;
    height: 100%;
  }
  &__form {
    position: absolute;
    display: inline-block;
    right: 0;
    top: 0;
    width: 100%;
    height: 30px;
    background: @lgrey;
    padding: 0;
    margin: 0;
    z-index: 2;
    display: none;
    white-space: nowrap;
  }
  &__input {
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 0;
    background: @lgrey;
    width: 72%;
    font: 14px 'KSHelveticaNeueCYR-Roman';
    // line-height: 24px;
    padding-bottom: 10px;
    color: @text;
    vertical-align: top;
    border-bottom : 1px solid @grey;
    margin-right: 25px;
    display: inline-block;
    &:focus {
      outline: 0;
    }
    @media all and (max-width: 568px) {
      width: 84%;
      margin-right: 30px;
    }
    @media all and (max-width: 420px) {
      width: 77%;
      margin-right: 30px;
    }
    @media all and (max-width: 375px) {
      width: 75%;
      margin-right: 30px;
    }
    @media all and (max-width: 320px) {
      width: 70%;
      margin-right: 30px;
    }
  }
  &__button {
    position: absolute;
    display: inline-block;
    left: 35px;
    vertical-align: top;
    height: 0;
    width: 0;
    border: 0;
    margin-right: 10px;
    &:focus {
      outline: 0;
    }
    &:after {
      content: '';
      position: absolute;
      top: 5px;
      left: 0;
      width: 20px;
      height: 28px;
      background: url(../img/search.svg) no-repeat;
    }
  }
  &__button-cancel {
    position: absolute;
    right: 0;
    top: 3px;
    display: inline-block;
    width: 7%;
    height: 20px;
    // border: 1px solid red;
    background: url(../img/mobile-menu-close.svg) no-repeat;
    background-position: center;
  }
}