.js-masonry-gutter {
  width: 23px;
  @media all and (max-width: 1170px) {
    width: 20px;
  }
  @media all and (max-width: @tablet-breakpoint) {
    width: 20px;
  }
  @media all and (max-width: 830px) {
    width: 18px;
  }
  @media all and (max-width: 800px) {
    width: 16px;
  }
  @media all and (max-width: 768px) {
    width: 15px;
  }
  @media all and (max-width: @mobile-breakpoint) {
    width: 0;
  }
}
.block.js-masonry-item {
  float: none;
  max-width: 1200px;
}
.content.js-masonry-list {
  height: 100%;
  float: none;
}
.js-masonry-item.block-link,
.js-masonry-item.block-tags,
.js-masonry-item.sidebar-inner_events {
  display: none;
  @media all and (max-width: @mobile-breakpoint) {
    display: block;
  }
}
.block {
  position: relative;
  vertical-align: top;
  float: left;
  width: 32%;
  background-color: @white;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
  transition: 0.5s;
  @media all and (max-width: 1024px) {
    width: 47%;
  }
  @media all and (max-width: @mobile-breakpoint) {
    width: 100%;
  }
  a {
    display: inline-block;
    text-decoration: none;
  }
  .counts {
    bottom: 20px;
  }
  &_interview {
    .block__image {
      border-bottom: 6px solid @blue;
    }
    .block__category {
      color: @blue;
    }
  }
  &_article {
    .block__image {
      border-bottom: 6px solid @aqua;
    }
    .block__category {
      color: @aqua;
    }
  }
  &_video {
    .block__image {
      position: relative;
      border-bottom: 6px solid @orange;
    }
    .block__category {
      color: @orange;
    }
  }
  &__category {
    padding: 0;
    text-transform: uppercase;
    font-size: 13px;
    margin: 0 0 20px 0;
    line-height: 100%;
    @media all and (max-width: 1024px) {
      padding: 0;
      margin: 0;
      font-size: 11px;
    }
  }
  &__author {
    color: @grey;
    display: inline-block;
    white-space: nowrap;
    margin: 0 0 20px 0;
    line-height: inherit;
    font-size: 16px;
    @media all and (max-width: 1024px) {
      margin: 16px 0;
      font-size: 14px;
    }
  }
  &__header {
    display: inline-block;
    margin: 0 0 14px 0;
    color: @text;
    text-align: left;
    overflow: hidden;
    @media all and (max-width: 1024px) {
      margin: 0 0 9px 0;
    }
    @media all and (max-width: @mobile-breakpoint) {
      max-height: 72px;
    }
    h5 {
      font-size: 20px;
      line-height: 28px;
      @media all and (max-width: 1024px) {
        font-size: 18px;
      }
      @media all and (max-width: @mobile-breakpoint) {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
  &__image {
    overflow: hidden;
    height: 240px;
    a {
      width: 100%;
      height: 100%;
    }
    img {
      display: inline-block;
      text-align: center;
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;';
    }
    &__horizont {
      width: 48%;
      max-height: 300px;
      overflow: hidden;
      @media all and (max-width: 1024px) {
        width: 100%;
      }
      img {
        border-bottom: 0;
        overflow: hidden;
        width: 100%; 
        // height: 100px;
      }
    }
  }
  &__article {
    max-height: 155px;
    overflow: hidden;
    vertical-align: bottom;
    p {
      line-height: 1.6rem;
      overflow: hidden;
      text-align: left;
      max-height: 125px;
      @media all and (max-width: 1024px) {
        font-size: 14px;
        line-height: 1.5em;
      }
    }
  }
  &__content {
    padding: 20px 20px 75px;
    font-size: 0;
  }
  &__button {
    // margin-top: 20px;
    @media all and (max-width: 1024px) {
      text-align: center;
      clear: both;
      margin-top: 20px;
    }
    @media all and (max-width: @mobile-breakpoint) {
      // display: none;
    }
  }
}
.block-full {
  float: left;
  width: 100%;
  padding: 35px 80px 40px;
  border-top: 6px solid @lsky;
  background-color: #ffffff;
  @media all and (max-width: @tablet-breakpoint) {
    // margin-bottom: 40px;
    padding: 80px 40px 60px;
    text-align: center;
  }
  @media all and (max-width: @mobile-breakpoint) {
    padding: 35px 20px 20px;
  }
  &_right {
    @media all and (max-width: @tablet-breakpoint) {
      padding: 40px 35px 30px; 
    }
    @media all and (max-width: @mobile-breakpoint) {
      float: none;
      display: block;
      padding: 30px 15px 30px; 
    }
  }
  .post__image {
    @media all and (max-width: @tablet-breakpoint) {
      margin: 0;
      width: 25%;
      min-width: auto;
    }
    @media all and (max-width: @mobile-breakpoint) {
      float: none;
      display: block;
      width: 160px;
      margin: 0 auto 25px;
    }
    & ~ blockquote {
      @media all and (max-width: @tablet-breakpoint) {
        width: 75%;
        float: right;
        margin: 0;
        margin-bottom: 0.5em;
        padding: 0;
        padding-left: 36px;
        text-align: left;
      }
      @media all and (max-width: @mobile-breakpoint) {
        float: none;
        display: block;
        width: 100%;
        margin: 0 auto;
        padding: 0;
      }
    }
  }
  &__image {
    float: left;
    width: 160px;
    min-width: 160px;
    margin-left: 15px;
    margin-top: 15px;
    @media all and (max-width: @tablet-breakpoint) {
      display: block;
      width: 100%;
      margin: 0;
      margin-bottom: 40px;
    }
    @media all and (max-width: @tablet-breakpoint) {
      margin-bottom: 30px;
    }
    img {
      @media all and (max-width: @tablet-breakpoint) {
        display: block;
        width: 150px;
        margin: 0 auto;
      }
    }
  }
  blockquote {
    width: 75%;
    padding-right: 165px;
    float: right;
    margin: 0;
    margin-bottom: 0.5em;
    padding: 0;
    color: @mark;
    font: 30px 'KSHelveticaNeueCYR-Light';
    &:last-child {
      margin-bottom: 0;
    }
    @media all and (max-width: @tablet-breakpoint) {
      font-size: 20px;
      width: 100%;
      margin-bottom: 16px;
    }
    @media all and (max-width: @mobile-breakpoint) {
      text-align: left;
      margin-bottom: 15px;
    }
  }
  p {
    font: 16px 'KSHelveticaNeueCYR-Roman';
    float: right;
    color: @text;
    width: 75%;
    line-height: 1.3em;
    margin-bottom: 0.5em;
    @media all and (max-width: @tablet-breakpoint) {
      font: 14px 'KSHelveticaNeueCYR-Light';
      line-height: 20px;
      width: 100%;
    }
    @media all and (max-width: @mobile-breakpoint) {
      text-align: left;
    }
  }
}
