.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,250, 0.7);
  // overflow-y: hidden;
  overflow-y: auto;
  z-index: 999;
  &.active {
    display: block;
  }
}
.block.popup-video {
  width: 560px;
  @media all and (max-width: @tablet-breakpoint) {
    width: 80%;
  }
}
.popup-video {
  background-color: @white;
  border-top: 6px solid @orange;
  float: none;
  display: block;
  margin: 100px auto;
  z-index: 999;
  box-sizing: content-box;;
  padding: 15px 10px 5px;
  @media all and (max-width: @tablet-breakpoint) {
    padding: 15px 30px 5px;
    box-sizing: border-box;
  }
  @media all and (max-width: @mobile-breakpoint) {
    padding: 15px 10px 5px;
    box-sizing: border-box;
  }
  &__header {
    margin-bottom: 20px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 40px;
    @media all and (max-width: @tablet-breakpoint) {
      font-size: 24px;
    }
    @media all and (max-width: @mobile-breakpoint) {
      font-size: 14px;
      padding-top: 10px;
      margin-bottom: 10px;
    }
  }
  &__frame {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    @media all and (max-width: @tablet-breakpoint) {
      
    }
  }
  &__btn {
    position: relative;
    display: block;
    width: 40%;
    height: 55px;
    margin: 50px auto;
    p {
      display: block;
      width: 70%;
    }
  }
}