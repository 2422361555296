.main {
  display: inline-block;
  width: 65%;
  @media all and (max-width: 1024px) {
    width: 100%;
  }
}
.menu_main {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  text-transform: uppercase;
  font: 16px 'KSHelveticaNeueCYR-Bold';
  font-weight: 400;
  @media all and (max-width: @tablet-breakpoint) {
    font-size: 0.9rem;
    margin-bottom: 25px;
  }
  @media all and (max-width: @mobile-breakpoint) {
    display: none;
  }
}