/*------------------------------------------------------------------------------
/*            Блок подписки
------------------------------------------------------------------------------*/

.subscribe-block {
  position: relative;
  height: 120px;
  margin: 0 0 20px 0;
  width: 100%;
  display: inline-block;
  @media all and (max-width: 1024px) {
    height: 140px;
  }
  @media all and (max-width: @mobile-breakpoint) {
    height: 230px;
    margin: 0 0 5px 0;
  }
}
.subscribe-item {
  position: absolute;
  top: 0;
  height: 120px;
  padding: 0 40px;
  width: 100%;
  color: @mark;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
  background-color: @white;
  border-top: 6px solid @lsky;
  background-color: @white;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05); 
  @media all and (max-width: 1024px) {
    height: 140px;
    padding: 25px 0;
    flex-direction: column;
  }
  @media all and (max-width: @mobile-breakpoint) {
    height: 100%;
    padding: 16px 0;
  }
  &_success {
    justify-content: center;
  }
  &_success &__header {
    white-space: normal;
  }
  &.hidden {
    display: none;
  }
  &__header {
    display: inline-block;
    font: 30px 'KSHelveticaNeueCYR-Light';
    white-space: nowrap;
    @media all and (max-width: 1170px) {
      font-size: 24px;
    }
    @media all and (max-width: @tablet-breakpoint) {
      font-size: 18px;
    }
    @media all and (max-width: @mobile-breakpoint) {
      width: 100%;
      text-align: center;
    }
    .logo {
      position: relative;
      bottom: 2px;
    }
  }
  &__form {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: 20px;
      height: 16px;
      margin-top: 17px;
      background-image: url(../img/email.svg);
      background-size: cover;
      @media all and (max-width: @mobile-breakpoint) {
        margin-top: 3px;
      }
    }
    @media all and (max-width: @mobile-breakpoint) {
      width: 90%;
    }
  }
  &__input {
    position: relative;
    width: 340px;
    border: 0;
    border-bottom: 1px solid @blue;
    margin-right: 30px;
    padding-left: 40px;
    padding-bottom: 16px;
    background: none;
    @media all and (max-width: 1100px) {
      width: 250px;
    }
    @media all and (max-width: @tablet-breakpoint) {
      width: 320px;
      padding-bottom: 9px;
      // height: 45px;
    }
    @media all and (max-width: @mobile-breakpoint) {
      margin-right: 0;
      width: 100%;
      vertical-align: bottom;
    }
    &.error {
      border-color: @red;
      color: @red;
    }
    &:focus {
      outline: 0;
    }
  }
  &__btn {
    width: 190px;
    @media all and (max-width: 1024px) {
      width: 180px;
      height: 45px;
    }
    @media all and (max-width: @mobile-breakpoint) {
      display: block;
      margin: 20px auto;
    }
  }
  .logo {
    display: inline-block;
    margin-top: 5.5px;
    height: 28px;
    width: 180px;
    vertical-align: top;
    @media all and (max-width: 1170px) {
      width: 150px;
      height: 22px;
    }
    @media all and (max-width: 1024px) {
      margin-top: -1px;
      margin-left: 5px;
    }
    @media all and (max-width: @mobile-breakpoint) {
        width: 180px;
        height: 24px;
        display: block;
        margin: 15px auto;
    }
  }
  
}

/*------------------------------- Управление видимостью ------------------------------------------*/
.js-subscribe-hidden {
  display: none;
}

// .subscribe-header {
// 	padding-left: 40px;
// 	font: 30px 'KSHelveticaNeueCYR-Light';
// 	white-space: nowrap;
//   @media all and (max-width: 640px) {
//     padding: 0;
// 		width: 100%;
//     text-align: center;
// 	}
// }
// .subscribe-form {
// 	width: 45%;
// 	padding-right: 40px;
// 	white-space: nowrap;
// 	@media all and (max-width: 640px) {
// 		margin: 20px 0;
// 		padding: 0;
// 		text-align: center;
// 		width: 100%;
//   }

// 	&__input {
// 		width: 50%;
// 	}
// 	&__btn {
//     @media all and (max-width: 1024px) {
// 			font-size: 12px;
//       width: 180px;
//     }
//   }
// 	@media all and (max-width: 640px) {
// 		width: 100%;
// 		margin: 30px 0;
// 	}
// }
