.block-contacts {
  float: left;
  width: 80%;
  @media all and (max-width: @tablet-breakpoint) {
    position: relative;
    float: none;
    display: block;
    padding: 0 25px;
    width: 100%;
  }
  @media all and (max-width: @mobile-breakpoint) {
    padding: 0;
  }
  h4 {
    line-height: 100%;
    margin-top: 40px;
    margin-bottom: 15px;
    @media all and (max-width: @tablet-breakpoint) {
      margin: 0;
      margin-bottom: 15px;
      font-size: 16px;
    }
  }
  &__item {
    // display: inline-block;
    float: left;
    width: 33.33333%;
    margin: 10px -2px;
    vertical-align: top;
    font: 16px 'KSHelveticaNeueCYR-Light';
    p {
      margin: 0;
    }
    @media all and (max-width: @tablet-breakpoint) {
      font-size: 0;
      margin-left: 0;
      &:nth-child(1) {
        width: 100%;
      }
      &:nth-child(2) {
        width: 100%;
      }
      &:nth-child(3) {
        width: 100%;
      }
      &:nth-child(4) {
        position: absolute;
        width: 46.5%;
        top: 37px;
        right: 25px;
      }
    }
    @media all and (max-width: @mobile-breakpoint) {
      &:nth-child(n) {
        position: relative;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }
  &__header {
    display: block;
    margin-bottom: 10px;
    font: 16px 'KSHelveticaNeueCYR-Medium';
    @media all and (max-width: @tablet-breakpoint) {
      font-size: 14px;
      margin-bottom: 5px;
    }
  }
}