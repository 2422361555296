.sidebar-event {
  margin-bottom: 35px;
  a {
    text-decoration: underline;
  }
  p {
    line-height: 1.5em;
    margin-bottom: 7px;
    :last-child {
      margin-bottom: 0;
    }
  }
  &:last-child {
    margin-bottom: 0; 
  }
  &__tag {
    color: @mark;
    text-transform: uppercase;
    font-size: 13px;
    margin: 15px 0 20px 0;
  }
  &__header {
    color: @ltext;
  }
  &__content {
    margin: 20px 0 0;
  }

  &__pay {
    display: inline-block;
  }
  &__price {
    display: inline-block;
    vertical-align: top;
    font: 24px 'KSHelveticaNeueCYR-Roman';
    color: @ltext;
  }
  &__refer {
    color: #7d9bb2;
    height: 24px;
    display: inline-block;
    box-sizing: border-box;
    text-transform: uppercase;
    border: 1px solid @mark;
    border-radius: 3px;
    padding: 4px;
    margin: 2px 10px 0;
    font: 11px 'KSHelveticaNeueCYR-Light';
  }
  &__item {
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.sidebar-contacts {
  &__item {
    display: block;
    margin-bottom: 13px;
    color: @ltext;
  }
  &__name {
    white-space: nowrap;
    display: block;
    margin-bottom: 5px;
    font: 16px 'KSHelveticaNeueCYR-Medium';
  }
  &__phone {
    display: block;
    margin-bottom: 5px;
    font: 16px 'KSHelveticaNeueCYR-Light';
  }
}