.menu-mobile_btn {
  position: absolute;
  top: 8px;
  left: 20px;
  width: 18px;
  height: 12px;
  background: url(../img/mobile-menu.svg) no-repeat;
  display: none;
  @media all and (max-width: @mobile-breakpoint) {
    display: block;
  }
  & + .menu-mobile {
    display: none;
  }
  &.opened {
    width: 12px;
    height: 12px;
    background: url(../img/mobile-menu-close.svg) no-repeat;
    background-size: cover;
    left: 23px;
    // & + .menu-mobile {
    //   display: block;
    // }
  }
}
.menu-mobile {
  position: absolute;
  top:  45px;
  // width: 100%;
  box-sizing: border-box;
  // border-bottom: 1px solid @blue;
  z-index: 9;
  display: none;
  // @media all and (max-width: @mobile-breakpoint) {
  //   display: block;
  // }
  // &:before {
  //   content: '';
  //   position: absolute;
  //   width: 50px;
  //   height: 50px;
  //   top: 10px;
  //   left: 10px;
  //   background-color: @lgrey;
  //   background: url(../img/mobile-menu-close.svg) no-repeat;

  // }
  &__list {
    padding: 0;
    margin: 0;
  }
  &__item {
    display: inline-block;
    width: 100%;
    text-align: center;
    font: 16px 'KSHelveticaNeueCYR-Bold';
    text-transform: uppercase;
    line-height: 60px;
    background: @lgrey;
    &:last-child {
      padding: 0 0 25px 0;
    }
    &_btn {
      position: relative;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: auto;
      color: white;
      background: @aqua;
      padding: 15px 20px;
    }
  }
  &__link {
    text-decoration: none;
  }
}