.autor-content {
  border-bottom: 0;
  @media all and (max-width: @tablet-breakpoint) {
    padding-bottom: 0;
  }
  &__image {
    display: none;
    @media all and (max-width: @tablet-breakpoint) {
      display: inline-block;
      float: left;
      width: 200px;
      // height: 200px;
      margin: 20px 16px 0 0;
      img {
        left: 0;
        margin: 0;
        width: 100%;
      }
    }
    @media all and (max-width: @mobile-breakpoint) {
      width: calc(~"100% + 35px");
      display: block;
      clear: both;
      height: 100%;
      margin: 0 -20px 20px;
    }
  }
  &__specific {
    margin-top: 20px;
    @media all and (max-width: @tablet-breakpoint) {
      min-height: 200px;
      // max-height: 200px;
      overflow: hidden;
      display: inline-block;
      width: 70%;
      vertical-align: top;
      margin: 20px 0 0 0;
    }
    @media all and (max-width: @mobile-breakpoint) {
      width: 100%;
      display: block;
    }
    p {
      @media all and (max-width: @tablet-breakpoint) {
        font-size: 12px;
        margin-bottom: 10px;
        line-height: 17px;
      }
    }

  }
  h2, {
    @media all and (max-width: @tablet-breakpoint) {
      margin-top: 30px;
      margin-bottom: 25px;
      clear: both;
      display: block;
      font: 20px 'KSHelveticaNeueCYR-Light';
    }
  }
  li {
    font-size: 14px;
    line-height: 20px;
  }
}
.autor-list {
  padding-bottom: 0;
  margin-bottom: 100px;
  @media all and (max-width: @tablet-breakpoint) {
    margin-bottom: 40px;
    padding: 0 10px;
  }
  @media all and (max-width: @mobile-breakpoint) {
    padding: 0;
  }
  &__btn{
    float: left;
    width: 100%;
    margin-top: 50px;
    text-align: center;
    @media all and (max-width: @tablet-breakpoint) {
      margin-top: 30px;
    }
  }
}
.autor-item {
  width: 585px;
  margin: 0 0 30px 0;
  height: 180px;
  position: relative;
  background-color: @white;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
  transition: 0.5s;
  a {
    text-decoration: none;
  }
  &:nth-child(2n+1) {
    float: left;
  }
  &:nth-child(2n) {
    float: right;
  }
  @media all and (max-width: 1170px) {
    width: 49%;
  }
  @media all and (max-width: @tablet-breakpoint) {
    float: none;
    display: block;
    width: 100%;
    height: 150px;
    margin: 0 0 10px 0;
    padding: 15px;
    box-sizing: border-box;
  }
  @media all and (max-width: @tablet-breakpoint) {
    height: auto;
  }
  &__header {
    display: block;
    margin-top: 15px;
    margin-bottom: 20px;
    @media all and (max-width: @tablet-breakpoint) {
      margin: 0;
      margin-bottom: 8px;
    }
    h5 {
      font: 20px 'KSHelveticaNeueCYR-Medium';
      @media all and (max-width: @tablet-breakpoint) {
        font-size: 16px;
      }
    }
  }
  &__article {
    p {
      display: inline-block;
      max-height: 78px;
      overflow: hidden;
      @media all and (max-width: @tablet-breakpoint) {
        font-size: 13px;
        line-height: 20px;
      }
      @media all and (max-width: @mobile-breakpoint) {
        max-height: none;
      }
    }
    @media all and (max-width: @tablet-breakpoint) {
      display: inline-block;
      max-height: 80px;
      overflow: hidden;
    }
    @media all and (max-width: @mobile-breakpoint) {
      max-height: none;
    }
  }
  &__image {
    float: left;
    height: 180px;
    width: 180px;
    overflow: hidden;
    @media all and (max-width: 1170px) {
      width: 30%;
      height: 100%;
    }
    @media all and (max-width: @tablet-breakpoint) {
      width: 120px;
      height: 120px;
    }
    @media all and (max-width: 450px) {
      float: none;
      height: 200px;
      width: 200px;
    }
    img {
      height: 180px;
      width: auto;
       @media all and (max-width: @tablet-breakpoint) {
         height: 100%;
         width: 100%;
         object-fit: cover;
       }
    }
  }
  &__content {
    float: left;
    padding: 10px 20px;
    width: 405px;
    border-top: 6px solid @sky;
    @media all and (max-width: 1170px) {
      width: 70%;
    }
    @media all and (max-width: @tablet-breakpoint) {
      border: 0;
      padding: 0 10px 15px;
      width: 79%;
    }
    @media all and (max-width: @mobile-breakpoint) {
      width: 65%;
      padding: 0 0 0 10px;
    }
    @media all and (max-width: 450px) {
      float: none;
      margin-top: 12px;
      padding: 0;
      width: auto;
    }
  }
}
.autor-sidebar {
  padding: 0;
  @media all and (max-width: @tablet-breakpoint) {
    box-shadow: none;
  }
  &__image {
    @media all and (max-width: @tablet-breakpoint) {
      display: none;
    }
    img{
      width: 100%;
    }
  }
  &__content {
    padding: 30px 20px;
    @media all and (max-width: @tablet-breakpoint) {
      padding: 0 20px 30px;
    }
    .header {
      text-transform: uppercase;
      color: @sky;
      font-size: 13px;
      @media all and (max-width: @tablet-breakpoint) {
        margin: 0;
        padding: 30px 0 0;
        font-size: 11px;
      }
    }
    .phone-list {
      padding: 0;
      color: @ltext;
      font-size: 16px;
      @media all and (max-width: @tablet-breakpoint) {
        margin-bottom: 10px;
      }
      li {
        line-height: 100%;
        @media all and (max-width: @tablet-breakpoint) {
          display: inline-block;
          margin-right: 5px;
          font: 14px 'KSHelveticaNeueCYR-Light';
        }
      }
    }
    .phone-item {
      margin: 5px 0;
    }
    .email {
      text-decoration: underline;
      color: @black;
      @media all and (max-width: @tablet-breakpoint) {
        font: 14px 'KSHelveticaNeueCYR-Light';
      }
    }
  }
}
.author-articles {
  display: none;
  @media all and (max-width: @tablet-breakpoint) {
    display: block;
  }
}
.main {
  ~ h4,
  ~ .content {
    @media all and (max-width: @tablet-breakpoint) {
      display: none;
    }
  }
}