.block-tags {
  border: 0;
  background: none;
  box-shadow: none;
  &__header {
    display: inline-block;
    width: 100%;
    height: 30px;
    padding: 8px 20px;
    vertical-align: middle;
    background-color: @yellow;
    text-align: left;
    color: @white;
    font: 13px 'KSHelveticaNeueCYR-Roman';
    text-transform: uppercase;
    @media all and (max-width: 640px) {
      font-size: 11px;
      height: 26px;
    }
  }
  &__list {
    text-align: left;
    margin: 0;
    padding: 20px 20px 20px 20px;
    @media all and (max-width: @tablet-breakpoint) {
      // padding: 20px 15px 20px;
      padding: 15px 15px 15px;
    }
    li {
      margin: 4px 4px;
      @media all and (max-width: @mobile-breakpoint) {
        margin: 2px 4px;
      }
    }
  }
  &__item {
    display: inline-block;
    margin-right: 12px;
    line-height: 21px;
    text-transform: uppercase;
    a {
      font-size: 9px;
      text-decoration: none;
      color: #939499;
    }
    @media all and (max-width: @tablet-breakpoint) {
      color: #7D7E81;
    }
    &.tag-xs a {
      font-size: 11px;
      color: #7c7d81;
      @media all and (max-width: @tablet-breakpoint) {
        font-size: 11px;
        color: #7d7e81;
      }
    }
    &.tag-s a {
      font-size: 12px;
      color: #939499;
      @media all and (max-width: @tablet-breakpoint) {
        font-size: 12px;
        color: #7D7E81;
      }
    }
    &.tag-m a {
      font-size: 14px;
      color: #5f6063;
      @media all and (max-width: @tablet-breakpoint) {
        font-size: 13px;
        color: #7D7E81;
      }
    }
    &.tag-l a {
      font-size: 16px;
      color: #363638;
      @media all and (max-width: @tablet-breakpoint) {
        font-size: 14px;
        color: #4F4F4F;
      }
    }
    &.tag-xl a {
      font-size: 18px;
      color: #363638;
      @media all and (max-width: @tablet-breakpoint) {
        font-size: 15px;
        color: #4F4F4F;
      }
    }
    &.tag-2xl a {
      font-size: 20px;
      color: #171717;
      @media all and (max-width: @tablet-breakpoint) {
        font-size: 16px;
        color: #333333;
      }
    }
    &.tag-3xl a {
      font-size: 22px;
      color: #080808;
      @media all and (max-width: @tablet-breakpoint) {
        font-size: 18px;
        color: #333333;
      }
    }
  }
}

