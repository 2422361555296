.footer {
  position: relative;
  clear: both;
  // height: 100px;
  // margin-top: 100px;
  border-top: 2px solid @border-grey;
  &__list {
    margin: 30px auto;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    @media all and (max-width: @tablet-breakpoint) {
      padding: 0 25px;
    }
    @media all and (max-width: @mobile-breakpoint) {
      display: block;
      margin: 95px 20px 20px;
      &:after {
        content: "";
        display: table;
        clear: both;
      }
    }
    p {
      color: @footer-text;
      font: 14px 'KSHelveticaNeueCYR-Roman';
    }
  }
  &__item {
    width: 30%;
    display: inline-block;
    color: @footer-text;
    // @media all and (max-width: @tablet-breakpoint) {
    //   &:nth-child(1) {
    //     float: left;
    //     width: 60%;
    //   }
    //   &:nth-child(2) {
    //     position: absolute;
    //     top: 24px;
    //     left: calc( ~"50% - 95px" );
    //   }
    //   &:nth-child(3) {
    //     float: right;
    //     width: 40%;
    //   }
    // }
    @media all and (max-width: @tablet-breakpoint) {
      &:nth-child(2) {
        position: absolute;
        top: 20px;
        width: 210px;
        left: calc(~"50% - 120px");
      }
      &:nth-child(1) {
        float: left;
        width: 50%;
        text-align: left;
        margin-top: 40px;
      }
      &:nth-child(3) {
        float: right;
        width: 40%;
        margin-top: 40px;
      }
    }
    @media all and (max-width: @mobile-breakpoint) {
      &:nth-child(2) {
        display: block;
        width: 210px;
        left: calc(~"50% - 105px");
      }
      &:nth-child(1) {
        float: left;
        width: 100%;
        text-align: center;
        margin-top: 0;
      }
      &:nth-child(3) {
        float: right;
        width: 100%;
        margin-top: 0;
      }
    }
    p {
      line-height: 1.6em;
    }
  }
}

.logo-footer {
  @media all and (max-width: @mobile-breakpoint) {
    display: block;
    width: 100%;
    font-size: 0;
  }
  .logo-head {
    height: 35px;
  }
  .logo-subhead-footer {

  }
}


