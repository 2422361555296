.block-sidebar {
  width: 100%;
  margin-top: 0;
  padding: 0 20px 20px;
  &__red {
    border-top: 8px solid @orange;
  }
  &__lblue {
    border-top: 8px solid @lblue;
  }
  &__header {
    margin: 20px 0 30px;
    text-transform: uppercase;
    font-size: 13px;
  }
  &__item {
    border-bottom: 1px solid @divider;
    margin-bottom: 30px;
    padding-bottom: 20px;
    &:last-child {
      border-bottom: 0;
      margin-bottom: 0;
    }
  }
}
.btn_masonry {
    position: absolute;
    bottom: 0;
    width: 100%;
}
.sidebar {
  position: relative;
  vertical-align: top;
  transition: 0.5s;
  float: right;
  width: 32%;
  margin: 0;
  a {
    display: inline-block;
    text-decoration: none;
  }
  .sidebar-inner__list > a {
    display: block;
  }
  &_lblue {
    border-top: 6px solid @lblue;
  }
  @media all and (max-width: @tablet-breakpoint) {
    width: 100%;
    margin: 0;
  }
}

.sidebar {
  &.js-masonry-fixed {
    position: absolute;
    right: 0;
    top: 0;
    @media all and (max-width: @tablet-breakpoint) {
      width: 47%;
      right: 5px;
      margin: 0;
    }
    @media all and (max-width: 850px) {
      right: 3px;
    }
    @media all and (max-width: @mobile-breakpoint) {
      right: 0;
      display: none;
    }
  }
}
.content {
  .sidebar {
    &.js-masonry-fixed {
      @media all and (max-width: @tablet-breakpoint) {
        margin-right: 0;
      }
    }
    @media all and (max-width: @tablet-breakpoint) {
      width: 47%;
      margin-right: 10px;
    }
    @media all and (max-width: @mobile-breakpoint) {
      width: 100%;
      margin: 0;
    }
  }
}