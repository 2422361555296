.block-top {
  height: 496px;
  width: 100%;
  margin: 0 0 20px 0;
  position: relative;
  vertical-align: top;
  float: left;
  background-color: @white;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
  transition: 0.5s;
  box-sizing: content-box;
  a {
    text-decoration: none;
  }
  @media all and (max-width: 1024px) {
    height: 300px;
  }
  @media all and (max-width: @mobile-breakpoint) {
    height: auto;
    margin: 0 0 10px 0;
  }
  &__content {
    max-width: 34%;
    height: 100%;
    float: left;
    padding: 26px 20px 60px 20px;
    box-sizing: border-box;
    font-size: 0;
    @media all and (max-width: 1200px) {
      width: 34%;
      max-width: 35%;
    }
    @media all and (max-width: 1024px) {
      width: 50%;
      max-width: 50%;
      padding: 15px 20px 60px 20px;
    }
    @media all and (max-width: @mobile-breakpoint) {
      width: 100%;
      max-width: 100%;
      float: left;
    }

  }
  &__category {
    margin-bottom: 27px;
    text-transform: uppercase;
    font: 13px 'KSHelveticaNeueCYR-Roman';
    @media all and (max-width: 1024px) {
      margin-bottom: 15px;
      font-size: 11px;
    }
  }
  &__author {
    display: block;
    margin-bottom: 30px;
    color: @grey;
    font: 16px 'KSHelveticaNeueCYR-Roman';
    @media all and (max-width: 1024px) {
       margin-bottom: 15px;
       font-size: 14px;
    }
  }
  &__header {
    margin: 0 0 21px 0;
    max-height: 108px;
    overflow: hidden;
    display: inline-block;
    h5 {
      font-size: 28px;
      line-height: 36px;
      @media all and (max-width: 1024px) {
        font-size: 22px;
      }
      @media all and (max-width: @mobile-breakpoint) {
        font-size: 18px;
        line-height: 24px;
      }
    }
    @media all and (max-width: @tablet-breakpoint) {
      margin: 0 0 18px 0;
      font-size: 18px;
      max-height: 80px;
    }
    @media all and (max-width: @mobile-breakpoint) {
      max-height: 98px;
      margin: 0 0 10px 0;
    }
  }
  &__article {
    display: inline-block;
    max-height: 130px;
    overflow: hidden;
    text-align: left;
    p {
      font: 18px 'KSHelveticaNeueCYR-Light';
      line-height: 26px;
    }
    @media all and (max-width: @tablet-breakpoint) {
      max-height: 57px;
      p {
        font-size: 14px;
        line-height: 1.4em;
        max-height: 76px;
        overflow: hidden;
      }
    }
  }
  &__video {
    border-color: @red;
  }
  &__events {
    border-color: @lblue;
  }
  &__image {
    width: 66%;
    position: relative;
    float: left;
    height: 100%;
    overflow: hidden;
    @media all and (max-width: 1024px) {
      width: 50%;
    }
    @media all and (max-width: @mobile-breakpoint) {
      width: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;';
    }
    a {
      display: block;
      height: 100%;
    }
  }
  &_interview {
    border-top: 6px solid @blue;
    .block-top__category {
      color: @blue;
    }
  }
  &_article {
    border-top: 6px solid @aqua;
    .block-top__category {
      color: @aqua;
    }
  }
  &_event {
    border-top: 6px solid @lblue;
    .block-top__category {
      color: @lblue;
    }
  }
  &_online {
    border-top: 6px solid @red;
    .block-top__category {
      display: inline-block;
      padding: 3px 10px;
      border: 1px solid @red;
      color: @red;
      font-size: 11px;
    }
  }
  &_events {
    border-top: 6px solid @lblue;
    .block-top__category {
      color: @lblue;
    }
  }
  &__header {
    font-size: 28px;
  }
  &__counts {
    bottom: 20px;
  }
}
