.search-top {
  position: relative;
  padding-top: 16px;
  @media all and (max-width: @tablet-breakpoint) {
    position: absolute;
    top: 50px;
    right: 20px;
    border: 0;
    width: auto;
    padding-top: 0;
    &:placeholder {
      color: none;
    }
  }
	@media all and (max-width: @tablet-breakpoint) {
		position: absolute;
		top: -3px;
		font-size: 12px;
		::-webkit-input-placeholder {color: #f4f4f4;}
		::-moz-placeholder          {color: #f4f4f4;}/* Firefox 19+ */
		:-moz-placeholder           {color: #f4f4f4;}/* Firefox 18- */
		:-ms-input-placeholder      {color: #f4f4f4;}
	}
  &__input {
    border: 0;
    border-bottom: 1px solid @input-text;
    background: none;
    padding-bottom: 15px;
    padding-right: 20px;
    // height: 35px;
    // line-height: 35px;
    vertical-align: top;
    @media all and (max-width: @tablet-breakpoint) {
      border-bottom: 0;
      padding-bottom: 0;
      padding-right: 23px;
      // border: 1px solid red;
      height: 30px;
    }
    &:focus {
      outline: 0;
    }
  }
  &__button {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0;
    right: 0;
    border: 0;
    background: none;
    @media all and (max-width: @tablet-breakpoint) {
      top: 0px;
    }
    &:focus {
      outline: 0;
    }
    &:after {
      content: '';
      position: absolute;
      width: 18px;
      height: 19px;
      background: url('../img/search.svg') no-repeat center;
      top: 20px;
      right: 0;
      cursor: pointer;
      @media all and (max-width: @tablet-breakpoint) {
        top: 8px;
      }
    }
  }
}