/*------------------------------------------------------------------------------
                              КНОПКИ Обычные
------------------------------------------------------------------------------*/
.btn {
  clear: both;
  display: inline-block;
  // width: 180px;
  margin: 0 auto;
  white-space: nowrap;
  height: 50px;
  padding: 15px 20px;
  border: 0;
  text-transform: uppercase;
  text-align: center;
  font: 14px 'KSHelveticaNeueCYR-Bold';
  color: @white;
  cursor: pointer;
  @media all and (max-width: @mobile-breakpoint) {
    padding: 17px 20px;
  }
  &:focus {
    outline: 0;
  }
  &:hover {
    color: @white;
    transition: 0.3s;
  }
  &:active {
    color: @white;
  }
  /*                                     Положение кнопок                                         */
  &_right {
    margin-right: 0;
  }
  &_left {
    float: left;
  }
  &_center {
    display:block; 
    width: 180px;
    text-align:center; 
    margin: 0 auto;
    @media all and (max-width: @mobile-breakpoint) {
      width: 85%;
    }
  }
  /*                                       Цвет кнопок                                            */
  &_aqua {
    border: 0;
    background-color: @btn_aqua_normal;
    transition: 0.3s;
    &:hover {
      background-color: @btn_aqua_hover;
      transition: 0.3s;
    }
    &:active {
      background-color: @btn_aqua_active;
    }
  }
  &_blue {
      background-color: @btn_blue_normal;
      transition: 0.3s;

    &:hover {
      background-color: @btn_blue_hover;
      transition: 0.3s;
    }
    &:active {
      background-color: @btn_blue_active;
    }
  }
  &_yellow {
    border: 0;
    background-color: @yellow;
    transition: 0.3s;
    &:hover {
      background-color: @yellow;
      transition: 0.3s;
    }
    &:active {
      background-color: @yellow;
    }
  }
  /*                                    Состояние кнопок                                          */
  &_disable {
    // cursor: none;
    background-color: @btn_disable;
    &:hover {
      background-color: @btn_disable;
      transition: 0.3s;
    }
  }
}

/*------------------------------------------------------------------------------
                          КНОПКИ (цвета)
------------------------------------------------------------------------------*/
.btn-basic {
  text-align:center; 
  border: 1px solid @input-text;
  font: 14px 'KSHelveticaNeueCYR-Roman';
  color: @btn-text;
  transition: 0.3s;
  // width: auto;
  &_orange {
    color: @orange;
    border-color: @orange;
  }
  &_aqua {
    color: @aqua;
    border-color: @aqua;
  }
  &:hover {
    border-color: @ltext;
    color: @ltext;
    transition: 0.3s;
  }
  &:active {
    border-color: @blue;
    color: @blue;
    transition: 0.3s;
  }
}
.btn-sidebar {
  position: relative;
  color: @lblue;
  border-color: @lblue;
  text-align: left;
  padding-left: 25px;
  padding-right: 50px;
  display: inline-block;
  width: auto;
  font-size: 13px;
  &_sky {
    &:after {
      content: '';
      position: absolute;
      width: 13px;
      height: 13px;
      top: 16px;
      right: 25px;
      background: url('../img/arrow-sky.svg') no-repeat;
      @media all and (max-width: @tablet-breakpoint) {
        content: none;
      }
    }
  }
  &_aqua {
    &:after {
      content: '';
      position: absolute;
      width: 12px;
      height: 13px;
      top: 16px;
      right: 25px;
      background: url('../img/arrow-aqua.svg') no-repeat;
      transform: rotate(90deg);
      @media all and (max-width: @tablet-breakpoint) {
        content: none;
      }
    }
  }
  &_red {
    &:after {
      content: '';
      position: absolute;
      width: 12px;
      height: 13px;
      top: 16px;
      right: 25px;
      background: url('../img/arrow-red.svg') no-repeat;
      transform: rotate(90deg);
      @media all and (max-width: @tablet-breakpoint) {
        content: none;
      }
    }
  }
  @media all and (max-width: @tablet-breakpoint) {
    font-size: 12px;
    height: auto;
    margin-top: 20px;
    padding-right: 25px;
  }
  @media all and (max-width: @mobile-breakpoint) {
    text-align: center;
    width: 85%;
  }
  &:hover {
    color: @text;
    border-color: @text;
  }
  &:active {
    color: @text;
    border-color: @text;
  }
  // &:after {
  //   content: '';
  //   position: absolute;
  //   right: 25px;
  //   display: inline-block;
  //   width: 15px;
  //   height: 15px;
  //   transform: rotate(90deg);
  //   background: url('../img/arrow.svg');
  // }
}

/*------------------------------------------------------------------------------
                              КНОПКИ Иконки
------------------------------------------------------------------------------*/
.i-btn {
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 20px;
  height: 20px;
  padding: 10px;
  border: 0;
  background-size: cover;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
  &_search {
    width: 16px;
    height: 16px;
    background: url('../img/search.svg') no-repeat center;
    margin: auto;
  }
  &_close {
    width: 16px;
    height: 16px;
    right: 20px;
    top: 20px;
    background: url('../img/i-close.svg') no-repeat center;
  }
  &_close-black {
    width: 16px;
    height: 16px;
    right: 20px;
    top: 20px;
    background: url('../img/i-close-black.svg') no-repeat center;
  }
  &_close-orange {
    width: 16px;
    height: 16px;
    right: 20px;
    top: 20px;
    background: url('../img/i-close-orange.svg') no-repeat center;
  }
}
.i-btn-play {
  position: absolute;
  display: block;
  z-index: 1;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: rgba( 255, 255, 255, 0.25 );
  box-shadow: 0 0 100px rgba( 0, 0, 0, 0.5 );
  top: calc(~"50% - 50px");
  left: calc(~"50% - 50px");
  cursor: pointer;
  &:after {
    content: '';
    position: absolute;
    top: calc(~"50% - 20px");
    left: calc(~"50% - 10px");
    width: 30px;
    height: 46px;
    background: url(../img/i-play.svg) no-repeat;
  }
}