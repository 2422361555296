.block-search-tags {
  float: none;
  display: block;
  width: 100%;
  margin-bottom: 40px;
  border-top: 6px solid @yellow;
  padding: 0 10px 20px;;
  background-color: @white;
  text-transform: uppercase;
  @media all and (max-width: @mobile-breakpoint) {
    margin-bottom: 25px;
  }
  .btn {
    padding: 10px 20px;
    height: auto;
    font: 13px 'KSHelveticaNeueCYR-Roman';
  }
  ul {
    padding: 0;
    text-align: left;
  }
  &__list {
    @media all and (max-width: @mobile-breakpoint) {
      margin-bottom: 10px;
    }
  }
  &__item {
    display: inline-block;
    margin: 7px 10px;
    @media all and (max-width: @mobile-breakpoint) {
      margin: 0 10px;
    }
    label {
      display: inline-block;
      border-bottom: 2px solid;
      border-color: @white;
      transition: border-color 0.3s;
      padding-bottom: 3px;
      cursor: pointer;
    }
    input {
      display: none;
    }
    input:checked + label {
      border-color: @yellow;
      transition: border-color 0.3s;
    }
    &.tag-xs {
      font-size: 11px;
      @media all and (max-width: @mobile-breakpoint) {
        color:#7D7E81;
      }
      label {
        padding-bottom: 5px;
      }
    }
    &.tag-s {
      font-size: 12px;
      @media all and (max-width: @mobile-breakpoint) {
        color:#7D7E81;
      }
      label {
        padding-bottom: 5px;
      }
    }
    &.tag-m {
      font-size: 14px;
      @media all and (max-width: @mobile-breakpoint) {
        color:#7D7E81;
      }
      label {
        padding-bottom: 5px;
      }
    }
    &.tag-l {
      font-size: 16px;
      @media all and (max-width: @mobile-breakpoint) {
        color:#4F4F4F;
      }
      label {
        padding-bottom: 5px;
      }
    }
    &.tag-xl {
      font-size: 18px;
      @media all and (max-width: @mobile-breakpoint) {
        color:#333333;
      }
      label {
        padding-bottom: 5px;
      }
    }
    &.tag-2xl {
      font-size: 20px;
      @media all and (max-width: @mobile-breakpoint) {
        color:#333333;
      }
      label {
        padding-bottom: 5px;
      }
    }
    &.tag-3xl {
      font-size: 22px;
      @media all and (max-width: @mobile-breakpoint) {
        color:#333333;
      }
      label {
        padding-bottom: 5px;
      }
    }
  }
}
