.block-wide {
  width: 790px;
  position: relative;
  margin: 0 0 20px 0;
  vertical-align: top;
  float: left;
  background-color: @white;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
  transition: 0.5s;
  display: flex;
  @media all and (max-width: 1180px) {
    width: 65%;
  }
  @media all and (max-width: @tablet-breakpoint) {
    width: 47.3%;
    display: inline-block;
    float: right;
    margin: 0 10px 20px 0;
    &:nth-child(2n) {
      float: left;
      margin: 0 10px 20px 10px;
    }
  }
  @media all and (max-width: @mobile-breakpoint) {
    width: 100%;
    float: none;
    margin: 0;
    margin-bottom: 10px;
    &:nth-child(2n) {
      margin: 0;
      float: none;
    }
  }
  a {
    text-decoration: none;
  }
  &__image {
    position: relative;
    display: inline-block;
    overflow: hidden;
    width: 380px;
    @media all and (max-width: @tablet-breakpoint) {
      display: block;
      height: 200px;
      width: 100%;
    }
    img {
      position: relative;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;';
    }
  }
  &__content {
    flex: 2 1 300px;
    padding: 20px 20px 50px;
    font-size: 0;
    @media all and (max-width: @tablet-breakpoint) {
      display: block;
      width: 100%;
      padding: 20px 20px 55px;
    }
  }
  &__author {
    color: @grey;
    display: inline-block;
    white-space: nowrap;
    margin: 0 0 20px 0;;
    font-size: 16px;
    @media all and (max-width: @tablet-breakpoint) {
      margin: 0 0 20px 0;
      font-size: 14px;
    }
  }
  &__header {
    margin: 0 0 14px 0;
    color: @text;
    text-align: left;
    display: inline-block;
    @media all and (max-width: @tablet-breakpoint) {
      margin: 0 0 10px 0;
    }
    h5 {
      font-size: 20px;
      @media all and (max-width: @tablet-breakpoint) {
        font-size: 18px;
      }
    }
  }
  &__article {
    max-height: 155px;
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    p {
      font: 16px 'KSHelveticaNeueCYR-Light';
      line-height: 24px;
      overflow: hidden;
      text-align: left;
      @media all and (max-width: @tablet-breakpoint) {
        font-size: 14px;
        line-height: 20px;
      }
    }
    @media all and (max-width: @mobile-breakpoint) {
      overflow: hidden;
      max-height: 95px;
    }
  }
  &__counts {
    bottom: 15px;
  }
  &__btn {
    position: absolute;
    bottom: 15px;
    width: 790px;
    display: block;
    clear: both;
    @media all and (max-width: @tablet-breakpoint) {
      width: 100%;
      bottom: 0;
    }
    @media all and (max-width: @mobile-breakpoint) {
      position: relative;
      margin: 20px 0 20px;
    }
    &_full {
      width: 100%;
    }
  }
  &_article {
    .block-wide__content {
      border-top: 6px solid @aqua;
    }
  }
  &_event {
    .block-wide__content {
      border-top: 6px solid @lblue;
    }
  }
  &_video {
    .block-wide__content {
      border-top: 6px solid @orange;
    }
  }
}