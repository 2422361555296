.sidebar-inner {
  position: relative;
  vertical-align: top;
  margin-bottom: 20px;
  padding: 20px 20px 30px 20px;
  float: left;
  width: 100%;;
  background-color: @white;
  box-shadow: 0 0 30px rgba(0,0,0,0.05);
  transition: 0.5s;
  @media all and (max-width: @mobile-breakpoint) {
    padding: 20px 15px 20px 15px;
    margin-bottom: 0px;
  }
  &__category {
    text-transform: uppercase;
    line-height: 100%;
    font-size: 13px;
    @media all and (max-width: @tablet-breakpoint) {
      font-size: 11px;
    }
  }
  &__ref {
    margin-bottom: 16px;
    width: 100%;
    color: @grey;
    font: 16px 'KSHelveticaNeueCYR-Roman';
    line-height: 100%;
    &:hover {
      color: @ltext;
      text-decoration: underline;
    }
    @media all and (max-width: @tablet-breakpoint) {
      margin-bottom: 15px;
      font-size: 14px;
    }
  }
  &__header {
    margin-bottom: 16px;
    @media all and (max-width: @tablet-breakpoint) {
      margin-bottom: 10px;
      display: block;
    }
    h5{
      font: 20px 'KSHelveticaNeueCYR-Medium';
      line-height: 28px;
      @media all and (max-width: @tablet-breakpoint) {
        font-size: 18px;
        display: inline-block;
        max-height: 63px;
        overflow: hidden;
      }
    }
  }
  &__article {
    p {
      font: 14px 'KSHelveticaNeueCYR-Light';
      line-height: 22px;
      @media all and (max-width: @tablet-breakpoint) {
        font-size: 12px;
      }
    }
  }
  a.sidebar-inner__article {
    // display: none;
  }
  &__list .block__button {
    margin-top: 12px;
  }
  &__list {
    float: left;
    &:after {
      content: "";
      display: table;
      clear: both;
    }
    @media all and (max-width: @tablet-breakpoint) {
      width: 100%;
    }
  }
  &__item {
    position: relative;
    padding: 22px 0 20px 0;
    border-top: 1px solid @divider;
    @media all and (max-width: @tablet-breakpoint) {
      vertical-align: top;
      padding: 20px 0 40px 0;
      width: 49%;
      border-top: 0;
      &:nth-child(2n+1) {
        float: left;
      }
      &:nth-child(2n) {
        float: right;
      }
    }
    @media all and (max-width: @mobile-breakpoint) {
      width: 100%;
      border-top: 1px solid @divider;
      margin-bottom: 15px;
      padding: 30px 0 35px 0;
      &:first-child {
        border: 0;
      }
    }
    /*  !!! Order !!! */
    &_wide {
      @media all and (max-width: @tablet-breakpoint) {
        width: 100%;
      }
      @media all and (max-width: @mobile-breakpoint) {
        padding: 30px 0 10px 0;
      }
      & a{
        @media all and (max-width: @mobile-breakpoint) {
          text-decoration: none;
        }
      }
    }
    &:first-child {
      border-top: 0;
      padding-top: 24px;
    }
    &:nth-child(2) {
      // border-top: 0;
    }
  }
  &__counts {
    bottom: 17px;
    @media all and (max-width: @tablet-breakpoint) {
      font-size: 14px;
    }
  }
  &_article {
    border-top: 6px solid @aqua;
    .sidebar-inner__category {
      color: @aqua;
    }
    .sidebar-inner__item {
      padding-bottom: 40px;
    }
    .btn-sidebar {
      color: @aqua;
      border-color: @aqua;
    }
  }
  &_events {
    border-top: 6px solid @lblue;
    .sidebar-inner__category {
      color: @lblue;
    }
    .btn-sidebar {
      color: @lblue;
      border-color: @lblue;
    }
  }
  &_video {
    border-top: 6px solid @orange;
    .sidebar-inner__category {
      color: @orange;
    }
    .sidebar-inner__item {
      padding-bottom: 18px;
    }
    .counts {
      bottom: 17px;
      position: static;
    }
    .btn-sidebar {
      color: @orange;
      border-color: @orange;
    }
  }
}

