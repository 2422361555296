.post {
  width: 100%;
  display: inline-block;
  position: relative;
  background-color: @white;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
  transition: 0.5s;

  margin: 0 0 0 0;
  // padding: 40px 80px;
  // border-top: 6px solid white;
  background-color: #ffffff;

  ul,
  ol {
    padding: 0;
    margin-bottom: 30px;
  }
  ol li,
  ul li {
    margin: 10px 0;
  }
  li ul,
  li ol {
    padding-left: 20px;
    margin-bottom: 0;
  }
  li ul li {
    &:before {
      content: "–";
      font-weight: bold;
      color: @mark;
      margin-right: 7px;
    }
  }
  img {
    position: relative;
    left: -20px;
    width: 780px;
    margin: 0 0 15px;
    @media all and (max-width: @tablet-breakpoint) {
      width:  calc(~"100% + 60px") ;
      min-width: 100%;
    }
    @media all and (max-width: @mobile-breakpoint) {
      display: block;
      margin: 15px 0 30px;
      height: auto;
      left: 0;
      width: 100%;
    }
  }
  ul li {
    position: relative;
    padding-left: 15px;
    color: @text;
    &:before {
      content: "\2022"; 
      position: absolute;
      left: 0;
      color: @mark;
      margin-right: 7px;
    }
  }
  ol {
    list-style: none;
    counter-reset: li;
  }
  ol li::before {
    content: counter(li)'.'; 
    color: @mark;
    display: inline-block;
    width: 1.2em;
  }
  li ol li::before {
    content: counter(li)')';
  }
  li {
    counter-increment: li
  }

  /*--------------------------------------------------------------------------*/
  /*                          TABLE
  /*--------------------------------------------------------------------------*/
  table {
    margin: 30px 0;
    width: 100%;
    border-spacing: 0;

    // @media all and (max-width: @tablet-breakpoint) {
    //   width: calc(~"100% + 40px");
    //   margin-left: -20px;
    //   margin-right: -20px;
    // }

    // @media all and (max-width: @mobile-breakpoint) {
    //   width: calc(~"100% + 35px");
    //   margin-left: -20px;
    //   margin-right: -20px;
    // }

    thead {
      background-color: @table-head;
      text-transform: uppercase;
      tr {
        height: 30px;
        th {
          font: 13px 'KSHelveticaNeueCYR-Medium';
          color: @text;
          text-align: left;
          padding: 0 20px;
        }
      }
    }
    tbody {
      tr {
        td {
          // max-width: 150px;
          min-width: 85px;
          vertical-align: top;
          padding: 20px 5px;
          border-bottom: 1px solid @table-head;
          color: @text;
          font: 15px 'KSHelveticaNeueCYR-Roman';

          @media all and (max-width: @tablet-breakpoint) {
            font-size: 14px;
            padding: 15px 10px;
          }

          @media all and (max-width: @mobile-breakpoint) {
            font-size: 11px;
            // padding: 10px;
            padding: 10px 2px;
            min-width: 55px;
          }
          @media all and (max-width: 320px) {
            font-size: 9px;
            // padding: 10px;
            padding: 10px 2px;
            min-width: 45px;
          }
        }
      }
    }
  }
/*                                 END TABLE                                  */
  p {
    font-family: 'KSHelveticaNeueCYR-Roman';
    color: @text;
    line-height: 1.3em;
    margin-bottom: 0.5em;
  }
  blockquote {
    float: right;
    margin: 0;
    padding: 0;
    margin-bottom: 0.5em;
    color: @mark;
    font: 30px 'KSHelveticaNeueCYR-Light';
    @media all and (max-width: @mobile-breakpoint) {
      font-size: 20px;
    }
  }
  h1, h2, h3, h4, h5, h6 {
    margin-top: 50px;
    margin-bottom: 35px;
  }
  h1 {
    font: 34px 'KSHelveticaNeueCYR-Medium';
  }
  h2 {
    font: 28px 'KSHelveticaNeueCYR-Roman';
  }
  h3 {
    font: 24px 'KSHelveticaNeueCYR-Medium';
  }
  h4 {
    font: 24px 'KSHelveticaNeueCYR-Light';
    text-transform: uppercase;
  }
  h5 {
    font: 20px 'KSHelveticaNeueCYR-Medium';
  }
  h6 {
    font: 13px 'KSHelveticaNeueCYR-Roman';
    color: @mark;
  }

  &__image {
    float: left;
    min-width: 160px;
    max-width: 160px;
    margin-left: 15px;
    margin-top: 10px;
    img {
      width: 100%;
    }
  }
  &__subheader {
    display: inline-block;
    margin-bottom: 45px;
    padding-left: 20px;
    position: relative;
    vertical-align: top;
    width: 100%;
    @media all and (max-width: @tablet-breakpoint) {
      margin-bottom: 0;
      min-height: 90px;
      padding: 80px 20px 20px;
    }
    @media all and (max-width: @mobile-breakpoint) {
      padding: 30px 15px 20px;
    }
  }
  &_event &__subheader {
    @media all and (max-width: @tablet-breakpoint) {
      padding-top: 90px;
    }
  }

  &__preview + &__info {
    @media all and (max-width: @mobile-breakpoint) {
      margin-top: 25px;
    }
  }

  &_article {
    .post__header {
      border-color: @aqua;
    }
  }
  &_event {
    .post__header {
      border-color: @lblue;
    }
  }
  &_video {
    .post__header {
      border-color: @orange;
    }
    .post__content {
      padding-bottom: 30px;
      margin-bottom: 0;
    }
    .post__subheader {
      margin-bottom: 35px
    }
    .post__author p,
    .post__author a{
      color: @grey;
    }
  }
  &_author {
    margin-bottom: 0;
    .post__header {
      border-color: @sky;
    }
  }
}
h1.post__header {
  font-size: 34px;
  margin: 35px 0 20px;
  padding: 0 20px 25px 20px;
  border-bottom: 6px solid;
  @media all and (max-width: @tablet-breakpoint) {
    text-align: left;
    font-size: 24px;
    margin: 30px 0 0;
    padding: 0 20px 20px 20px;
    line-height: 32px;
  }
  @media all and (max-width: @mobile-breakpoint) {
    font-size: 22px;
    line-height: 24px;
    padding: 0 15px 25px 15px;
  }
}
.post__preview {
  width: 60%;
  float: left;
  font: 22px 'KSHelveticaNeueCYR-Roman';
  color: @ltext;
  line-height: 32px;
  padding-right: 20px;
  @media all and (max-width: @tablet-breakpoint) {
    float: none;
    display: block;
    width: 100%;
    font-size: 18px;
  }
  @media all and (max-width: @mobile-breakpoint) {
    font-size: 16px;
    line-height: 23px;
    width: auto;
  }
}
.post__time {
  display: inline-block;
  width: 40%;
  @media all and (max-width: @tablet-breakpoint) {
    font-size: 12px;
    left: 0;
    margin-left: 20px;
    position: absolute;
    top: 30px;
    width: 300px;
  }
  @media all and (max-width: @mobile-breakpoint) {
    margin-left: 15px;
  }
  
}
.post__author {
  display: inline-block;
  text-align: left;
  @media all and (max-width: @tablet-breakpoint) {
    position: absolute;
    width: 50%;
    top: 25px;
    left: 0;
    margin-left: 20px;
  }
  @media all and (max-width: @mobile-breakpoint) {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    margin-bottom: 15px;
    margin-left: 0;
    width: 100%;
  }
  a {
    font: 16px 'KSHelveticaNeueCYR-Roman';
    text-decoration: none;
    display: block;
    color: @grey;
    text-align: left;
    width: 100%;
    margin: 0;
    &:hover {
      color: @ltext;
      text-decoration: underline;
    }
    @media all and (max-width: @tablet-breakpoint) {
      font-size: 14px;
      display: inline-block;
      width: auto;
      margin-right: 10px;
    }
    @media all and (max-width: @mobile-breakpoint) {
      display: block;
      margin-bottom: 3px;
    }
  }
}
.post__counts {
  bottom: 10px;
  display: inline-block;
  width: 40%;
  @media all and (max-width: @tablet-breakpoint) {
    position: absolute;
    width: 25%;
    top: 30px;
    right: 0;
    margin: 0 0 0 20px;
  }
  @media all and (max-width: @mobile-breakpoint) {
    bottom: auto;
    clear: both;
    display: block;
    margin-left: 0;
    position: relative;
    right: auto;
    top: auto;
    width: auto;
  }
}
.post__preview ~ .post__counts {
  @media all and (max-width: @mobile-breakpoint) {
    margin-top: 25px;
  }
}
.post__image-signature {
  display: inline-block;
  text-align: right;
  width: calc(~"100% - 20px");
  margin: 0 20px 35px 0;
  font: 11px 'KSHelveticaNeueCYR-Light';
}
.post__content {
  clear: both;
  // margin: 0 20px 20px 20px;

  margin: 0 0 20px;
  overflow: hidden;
  padding: 0 20px 40px;
  border-bottom: 1px solid @divider;
  @media all and (max-width: @tablet-breakpoint) {
    padding-bottom: 30px;
  }
  @media all and (max-width: @mobile-breakpoint) {
    padding: 0 15px;
  }
  p {
    margin: 0 0 10px 0;
    font: 16px 'KSHelveticaNeueCYR-Roman';
    line-height: 24px;
    @media all and (max-width: @tablet-breakpoint) {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .post__video-wraper {
    position: relative;
    padding-bottom: 64.8%;
    left: -20px;
    height: 0;
    margin-bottom: 35px;
    // padding-bottom: 56.25%; /* 16:9 */
    // padding-top: 25px;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(~"100% + 40px");
      height: 100%;
    }
  }
}
.post-image {
  margin: 25px -20px;
}
.tags-list {
  position: relative;
  display: block;
  margin: 16px 0 30px;
  padding-left: 35px;
  text-transform: uppercase;
  font-size: 11px;
  color: @grey;
  .tag-item {
    text-decoration: none;
    color: @grey;
    margin-left: 8px; 
    &:hover {
      text-decoration: underline;
      color: @ltext;
    }
  }
  .icon-tags {
    position: absolute;
    left: 0;
    top: 1px;
    margin-right: 2px;
    @media all and (max-width: @tablet-breakpoint) {
      top: 4px;
    }
  }
  @media all and (max-width: @tablet-breakpoint) {
    font: 14px 'KSHelveticaNeueCYR-Light';
  }
  @media all and (max-width: @mobile-breakpoint) {
    letter-spacing: 1px;
    padding-left: 40px;
    font-size: 14px;
    line-height: 20px;
  }
}
.post-list {
	width: 1200px;
}
.post-item {
	margin: 0;
  margin-bottom: 20px;
	width: 32.2%;
}
.post__btn {
  display: block;
  width: 240px;
  margin: 50px auto 100px;
  @media all and (max-width: @tablet-breakpoint) {
    margin: 30px auto 40px;
  }
}
.post__date-header {
  display: inline-block;
  font: 11px 'KSHelveticaNeueCYR-Roman';
  color: @grey;
  width: 80px;
  text-transform: uppercase;
  @media all and (max-width: @tablet-breakpoint) {
    margin-bottom: 4px;
  }
}
.post__date {
  display: inline-block;
  font: 16px 'KSHelveticaNeueCYR-Roman';
  width: 200px;
  @media all and (max-width: @tablet-breakpoint) {
    font-size: 12px;
    margin-bottom: 4px;
  }
}